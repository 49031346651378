/* Scoped Body and Page Layout */
.login-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #3b82f6; /* Blue background */
    padding: 1rem; /* Adds padding for better view on smaller screens */
  }
  
  .login-container {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  /* Logo styling */
  .login-logo {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    object-fit: cover; /* Ensures the logo remains square without distortion */
  }
  
  /* Heading */
  .login-heading {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #1f2937; /* Darker text for readability */
  }
  
  /* Form styling */
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 1.25rem; /* Adds consistent space between form fields */
  }
  
  .login-input {
    width: 93%;
    padding: 0.75rem;
    border: 1px solid #d1d5db; /* Light gray border */
  }
  
  .login-input:focus {
    border-color: #3b82f6; /* Blue border on focus */
    outline: none;
  }
  
  /* Button styling */
  .login-btn {
    width: 100%;
    padding: 0.75rem;
    border-radius: 0.375rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-btn.bg-blue-500 {
    background-color: #3b82f6; /* Blue button */
    color: white;
  }
  
  .login-btn.bg-blue-500:hover {
    background-color: #2563eb; /* Darker blue on hover */
  }
  
  /* Google login button */
  .google-login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ea4335; /* Google Red */
    color: white;
    border-radius: 9999px; /* Full rounded button */
    width: 100%;
    padding: 0.75rem;
    gap: 0.5rem; /* Adds space between the logo and text */
  }
  
  .google-login-btn:hover {
    background-color: #c1351d; /* Darker Google Red on hover */
  }
  
  .google-logo {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .relative {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 0;
  }
  
  hr {
    width: 100%;
    border-top: 1px solid #d1d5db;
  }
  
  span.bg-white {
    position: absolute;
    background-color: white;
    padding: 0 1rem;
    color: #6b7280; /* Gray text */
  }
  
  /* Footer text */
  p {
    margin-top: 1rem;
    font-size: 0.875rem;
    color: #4b5563; /* Dark gray text */
  }
  
  p span {
    color: #3b82f6;
    cursor: pointer;
    transition: text-decoration 0.2s ease;
  }
  
  p span:hover {
    text-decoration: underline;
  }
  
  /* Text link styles for switching between Register/Login */
  button.text-blue-500 {
    color: #3b82f6; /* Blue text for Register/Login toggle */
    background: none; /* Remove background */
    border: none; /* Remove border */
    font-size: 0.875rem; /* Adjust size to match the rest of the text */
    cursor: pointer;
    transition: text-decoration 0.2s ease;
  }
  
  button.text-blue-500:hover {
    text-decoration: underline; /* Underline on hover */
  }
  
  /* Responsive design adjustments */
  @media (max-width: 480px) {
    .login-container {
      width: 90%;
      padding: 1.5rem;
    }
    .login-heading {
      font-size: 1.25rem;
    }
    .login-input,
    .login-btn {
      padding: 0.5rem;
    }
  }
  