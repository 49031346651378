.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Cover the whole viewport */
    background: rgb(255, 255, 255); /* Semi-transparent background */
    position: absolute; /* Position it absolutely to cover the content */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000; /* Ensure it appears above other content */
  }
  
  .loader {
    height: 150px;
    width: 100px;
    border-radius: 55px 55px 10px 10px;
    position: relative;
    background: #FF3D00;
    background-image: linear-gradient(0deg,
      #f63a99 25%,
      #30dcf6 25%,
      #30dcf6 25%,
      #30dcf6 50%,
      #f2d200 50%,
      #f2d200 50%,
      #f2d200 75%,
      #70ca5c 75%);
    background-position: 0px 0px;
    background-size: auto 175px;
    background-repeat: repeat-y;
    animation: colorShift 6s linear infinite;
  }
  
  .loader:before {
    content: '';
    position: absolute;
    left: 10px;
    bottom: 15px;
    width: 15px;
    height: 100px;
    border-radius: 50px;
    background: rgb(255, 255, 255);
  }
  
  .loader:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    box-shadow: 0 15px 2px rgba(0, 0, 0, 0.25) inset;
    width: 32px;
    height: 45px;
    background: #E09C5F;
    border-radius: 0 0 12px 12px;
  }
  
  @keyframes colorShift {
    to {
      background-position: 0 175px;
    }
  }
  